<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.id"
        placeholder="请输入用户ID"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入用户名/电话"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.is_normal"
        placeholder="是否冻结"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已冻结" :value="0" />
      </el-select>
      <!-- <el-select
        v-model="listQuery.is_vip"
        placeholder="是否VIP"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="钻石会员" :value="3" />
        <el-option label="铂金会员" :value="2" />
        <el-option label="黄金会员" :value="1" />
        <el-option label="非会员" :value="0" />
      </el-select> -->
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
      <!-- <span class="filter-item">
        <download-excel
          :fields="json_fields"
          :fetch="out"
          :before-generate="startPost"
          :before-finish="endPost"
          :header="'用户信息'"
          type="xls"
          worksheet="用户信息"
          name="用户信息.xls"
        >
          <el-button type="success"> 导出用户信息 </el-button>
        </download-excel>
      </span> -->
    </div>
    <div class="filter-container">用户数量：{{ total || 0 }}</div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="ID" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="头像" min-width="110" align="center">
        <template slot-scope="scope">
          <img
            v-if="scope.row.avatar"
            :src="scope.row.avatar"
            class="user-avatar"
          />
        </template>
      </el-table-column>
      <el-table-column label="昵称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.nickname }}
        </template>
      </el-table-column>
      <el-table-column label="姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.username }}
        </template>
      </el-table-column>
      <el-table-column label="联系电话" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.phone }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="性别" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.gender == 0" type="info">未设置</el-tag>
          <el-tag v-else-if="scope.row.gender == 1" type="primary">男</el-tag>
          <el-tag v-else-if="scope.row.gender == 2" type="danger">女</el-tag>
        </template> -->
      </el-table-column>
      <!-- <el-table-column label="是否VIP" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_vip == 1" type="success">是</el-tag>
          <el-tag v-else type="info">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="会员到期时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.vip_expire_time }}
        </template>
      </el-table-column>
      <el-table-column label="全豆" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user_account ? scope.row.user_account.whole_bean : 0 }}
        </template>
      </el-table-column> -->
      <el-table-column label="注册时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_normal == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已冻结</el-tag>
        </template>
      </el-table-column>
       <el-table-column label="是否会员" min-width="110" align="center">
        <template slot-scope="scope" >
            <el-tag v-if="scope.row.is_vip==1"type="success">是</el-tag>
            <el-tag v-else type="danger">否</el-tag>
        
        </template>
      </el-table-column>
      <el-table-column label="已赠送会员时长" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.back_send_vip_day_num }}天
        </template>
      </el-table-column>
      <el-table-column label="积分" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user_account.point }}
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
      
        <template slot-scope="scope" style="margin:50px">
            <el-button
            size="mini"
            type="primary"
            @close="close()"
            @click="deduction(scope.row.id,)"
            >扣除积分</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @close="close()"
            @click="member(scope.row.id, '设置会员')"
            >赠送会员</el-button
          >

          <el-button
            v-if="scope.row.is_normal == 1"
            size="mini"
            type="danger"
            @click="handleForbid(scope.row.id, '冻结')"
            >冻结</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleForbid(scope.row.id, '解冻')"
            >解冻</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-form :model="numberValidateForm" ref="numberValidateForm" label-width="100px" class="demo-ruleForm" v-if="huiyuan">
    <el-form-item
        label="年龄111"
        prop="age"
        :rules="[
        { required: true, message: '年龄不能为空'},
        { type: 'number', message: '年龄必须为数字值'}
        ]"
    >
        <el-input type="age" v-model.number="numberValidateForm.age" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item>
        <el-button type="primary" @click="submitForm('numberValidateForm')">提交</el-button>
        <el-button @click="resetForm('numberValidateForm')">重置</el-button>
    </el-form-item>
    </el-form>

  </div>
</template>

<script>
import request from "@/utils/request";
import downloadExcel from "vue-json-excel";
export default {
  components: { downloadExcel },
  data() {
    return {
        huiyuan:false,
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        is_normal: "",
        is_vip: "",
      },
      //用户
      json_fields: {
        "用户ID ": "id",
        "昵称 ": "nickname",
        "姓名 ": "username",
        "联系电话 ": "phone",
        "性别 ": {
          field: "gender",
          //自定义回调函数
          callback: (value) => {
            if (value == 0) {
              return "未设置";
            } else if (value == 1) {
              return "男";
            } else if (value == 2) {
              return "女";
            }
          },
        },
         "是否vip ": {
          field: "is_vip",
          //自定义回调函数
          callback: (value) => {
            if (value == 0) {
              return "否";
            } else if (value == 1) {
              return "是";
            } 
          },
        },
        会员到期时间: {
          field: "vip_expire_time",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        全豆: {
          field: "user_account.whole_bean",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        注册时间: {
          field: "created_at",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
         "状态 ": {
          field: "is_normal",
          //自定义回调函数
          callback: (value) => {
            if (value == 0) {
              return "已冻结";
            } else if (value == 1) {
              return "正常";
            } 
          },
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    deduction(val){
        this.$prompt('此操作将扣除用户积分', '扣除积分', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputErrorMessage: '格式不正确',
          inputType:'Number'
          
        }).then(({ value }) => {
          request({
            url: "/api/backend/user/pointStore",
            method: "post",
            data: { user_id:val,change: value },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });       
        });

    },  
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/user/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    startPost() {
      this.loading = true;
    },
    endPost() {
      this.loading = false;
    },
    out() {
      this.listLoading = true;
      return request({
        url: "/api/backend/user/list",
        method: "get",
        params: {
          page: 1,
          limit: 999999999999,
          keyword: "",
          is_normal: "",
          is_vip: "",
        },
      })
        .then((result) => {
          var json_data = result.data.data;

          console.log(json_data);

          return json_data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    handleForbid(val, text) {
      this.$confirm("此操作将" + text + "该用户, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/user/changeNormal",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    close(){
      console.log(111111111);
    },
    member(val, text) {
        // if (!Number.isInteger(value)) {
        //     callback(new Error('请输入数字值'));
        //   } 
        this.$prompt('赠送会员时长 (天)', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        //   inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          inputErrorMessage: '格式不正确',
          inputType:'Number'
          
        }).then(({ value }) => {
          request({
            url: "/api/backend/user/sendVip",
            method: "post",
            data: { user_id:val,num: value },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });       
        });
      }
  },
};
</script>



<style rel="stylesheet/scss" lang="scss" scoped>
</style>
